import React from "react";
import Cube from "../Components/cube/Cube";
import "./ourgames.scss";

export default function OurGames() {
  let games = [
    {
      title: "Stick Slash",
      img: require("../images/games/stickslashicon.png"),
      appstore: "",
      playstore:
        "https://play.google.com/store/apps/details?id=com.madripple.stickslah",
    },
    {
      title: "Material Connect",
      img: require("../images/games/materialconnect.png"),
      appstore: "",
      playstore: "https://play.google.com/store/apps/details?id=com.madripple.MaterialConnect",
    },
  ];
  return (
    <div className="bg-dark">
      <div class="container">
        <div class="row py-5"></div>
      </div>
      <div className="container">
        <div class="row">
          <div class="col">
            <div className="module-border-wrap">
              <div
                className="module bg-dark"
                style={{
                  minHeight: "100vh",
                }}
              >
                <div class="row py-5"></div>
                <div class="row">
                  <h1
                    style={{ fontSize: 70 }}
                    className="py-5 Bfont text-white"
                  >
                    Our Games
                  </h1>
                </div>
                <div class="row">
                  {games.map((x) => {
                    return (
                      <div class="col-lg-3 col-10 mx-auto">
                        <Cube data={x} />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
