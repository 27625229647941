import React from "react";
import "./Contact.scss";
import * as Icon from "react-bootstrap-icons";

export default function Contact() {
  return (
    <div class="container-fluid bg-dark text-white">
      <div class="row py-5"></div>
      <div class="row">
        <div class="col">
          <h1 className="Bfont" style={{ fontSize: 70 }}>
            Get in touch!
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12 mx-auto py-5">
          <div class="row">
            <div class="col-lg-3 col-10 mx-auto contactComponent">
              <a className="btn text-white">
                <Icon.GeoAltFill className="my-4" size={50} fill="white" />
                <p>Lebanon - Beirut</p>
              </a>
            </div>
            <div class="col-lg-3 col-10 mx-auto contactComponent">
              <a href="tel:+96170026614" className="btn text-white">
                <Icon.TelephoneFill className="my-4" size={50} fill="white" />
                <p>+961 70-026614</p>
              </a>
            </div>
            <div class="col-lg-3 col-10 mx-auto contactComponent">
              <a href="mailto:info@madripple.com" className="btn text-white">
                <Icon.EnvelopeFill className="my-4" size={50} fill="white" />
                <p>info@madripple.com</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-6 col-12 p-lg-5 p-3 mx-auto bg-white color-dark text-dark"
          style={{ borderRadius: 10 }}
        >
          <h2 className="text-dark my-4">Contact Form</h2>
          <div class="row">
            <div class="col-lg-6 col-12">
              <form>
                <div class="row text-start">
                  <p style={{ fontWeight: "bold" }}>Your Name</p>
                  <div class="input-group input-group-icon mb-4">
                    <input
                      className="contactinput"
                      type="text"
                      placeholder="Full Name"
                    />
                    <div class="input-icon">
                      <Icon.PersonCircle fill="gray" size={25} />
                    </div>
                  </div>
                  <p style={{ fontWeight: "bold" }}>Mail</p>
                  <div class="input-group input-group-icon">
                    <input
                      className="contactinput"
                      type="email"
                      placeholder="Mail"
                    />
                    <div class="input-icon">
                      <Icon.Envelope fill="gray" size={25} />
                    </div>
                  </div>
                  <p style={{ fontWeight: "bold" }}>Phone</p>
                  <div class="input-group input-group-icon">
                    <input
                      className="contactinput"
                      type="tel"
                      placeholder="Your Phone"
                    />
                    <div class="input-icon">
                      <Icon.Phone fill="gray" size={25} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-6 col-12">
              <p style={{ fontWeight: "bold" }}>Phone</p>
              <div class="input-group input-group-icon">
                <div class="input-icon">
                  <Icon.Chat fill="gray" size={25} />
                </div>
                <textarea
                  rows={12}
                  className="contactinput ms-1"
                  multiple={true}
                  type="text"
                  placeholder="Your Message"
                />
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <div class="buttons">
                <button class="blob-btn">
                  Send Message
                  <span class="blob-btn__inner">
                    <span class="blob-btn__blobs">
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                      <span class="blob-btn__blob"></span>
                    </span>
                  </span>
                </button>
                <br />
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{display:"none"}}>
                <defs>
                  <filter id="goo">
                    <feGaussianBlur
                      in="SourceGraphic"
                      result="blur"
                      stdDeviation="10"
                    ></feGaussianBlur>
                    <feColorMatrix
                      in="blur"
                      mode="matrix"
                      values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 21 -7"
                      result="goo"
                    ></feColorMatrix>
                    <feBlend
                      in2="goo"
                      in="SourceGraphic"
                      result="mix"
                    ></feBlend>
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
