import "./App.css";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist//js/bootstrap";
import { Component } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";

class App extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <div
          className="container-fluid bg-dark"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <div class="loadingcontainer">
            <div class="paddle" id="left"></div>
            <div class="paddle" id="right"></div>
            <div class="ball"></div>
            <div class="loading text-center">
              <p>Generating</p>
              <p>Terrain...</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <div id="app" className="App loader">
                <Home />
              </div>
            }
          />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export default App;
