import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="container-fluid bg-dark text-light pt-5 pb-3">
      <div class="row">
        <div class="container">
          <div class="row" className="d-flex align-content-center">
            <div class="col d-lg-flex d-none"></div>
            <div class="col-lg-2 col-12 d-flex align-content-center">
              <img
                style={{ width: "50%", objectFit: "contain" }}
                src={require("../../images/BigLogoname.png")}
                alt={"logowithname"}
              />
            </div>
            <div class="col-2 d-lg-flex d-none"></div>
            <div
              class="col-lg-2 col-12"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p style={{ lineHeight: "60px" }}>All Rights Reseverd 2023 ©</p>
              <Link className="btn btn-light" to={"/privacypolicy"}>
                Privacy Policy
              </Link>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
