import React from "react";
import "./GoeyButton.css";

export default function GoeyButton(props) {
  return (
    <div>
      <a target={"_blank"} class="noselect linkbutton" href={props.link}>
        <span class="text">{props.text}</span>
        <span class="icon">
        {props.svg}
        </span>
      </a>
    </div>
  );
}
