import React from "react";
import "../scrolldownanim/ScrollDownAnim.css";

export default function ScrollDownAnim() {
  return (
    <div class="scrolldown" style={{ alignSelf: "center" }}>
      <div class="chevrons">
        <div class="chevrondown"></div>
        <div class="chevrondown"></div>
      </div>
    </div>
  );
}
