import React from "react";
import Hexagonbackground from "../Components/Hexagonbackground/Hexagonbackground";
import Scratch from "../Components/Scratch/Scratch";
import OurGames from "../Sections/OurGames";
import Contact from "../Sections/Contact/Contact";
import Team from "../Sections/Team/Team";
import Story from "../Sections/Story/Story";
import Story2 from "../Sections/Story/Story2";
import Footer from "../Sections/Footer/Footer";

export default function Home() {
  return (
    <>
      <Hexagonbackground />
      <Story />
      <Team />
      <OurGames />
      <Contact />
      <Footer />
    </>
  );
}
