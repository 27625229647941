import React, { useEffect, useRef, useState } from "react";
import ScrollDownAnim from "../scrolldownanim/ScrollDownAnim";
import "./Hexagonbackground.css";

let clientX = 0;
let clientY = 0;

export default function Hexagonbackground() {
  const [scrollPosition, setScrollPosition] = useState(0);

  let grid = useRef(null);
  let light = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
      const Lightelement = light.current;
      Lightelement.style.left = `${clientX}px`;
      Lightelement.style.top = `${clientY + scrollPosition}px`;
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    const Gridelement = grid.current;
    const Lightelement = light.current;

    Gridelement.addEventListener("mousemove", function (e) {
      clientX = e.clientX;
      clientY = e.clientY;
      Lightelement.style.left = `${e.clientX}px`;
      Lightelement.style.top = `${e.clientY + scrollPosition}px`;
    });
  }, [scrollPosition]);
  return (
    <div className="containerGrid">
      <div
        className="textoverGrid text-white"
        style={{
          justifyItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1 className="Bfont" style={{ fontSize: 100 }}>
          Mad Ripple
        </h1>
        <p>Startup Game Dev Studio</p>
        <ScrollDownAnim />
      </div>
      <header ref={grid} id="hex-grid">
        <div ref={light} className="light"></div>
        <div className="grid"></div>
      </header>
    </div>
  );
}
