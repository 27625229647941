import React from "react";
import "./Teamstat.scss";
import Card from "react-bootstrap/Card";
import * as Icon from "react-bootstrap-icons";

export default function Teamstat(props) {
  let data = props.data;
  return (
    <div className="container-fluid mainHolder">
      <div class="row">
        <div className="maintab">
          <Card className="bg-dark text-light" style={{ borderRadius: 20 }}>
            <Card.Img
              className="p-5"
              variant="top"
              style={{borderRadius:60}}
              src={data.image}
            />
            <Card.Body>
              <Card.Title>{data.name}</Card.Title>
              <Card.Text>{data.title}</Card.Text>
              { data.linkedin ?
                <a target={"_blank"} className="m-1 btn btn-primary" href={data.linkedin}>
                <Icon.Linkedin />
              </a> : ""}
              {data.insta ? <a target={"_blank"} className="m-1 btn btn-primary" href={data.insta}>
                <Icon.Instagram />
              </a> : ""}
            </Card.Body>
          </Card>
        </div>
        <div className="hiddentab">
          <Card
            className="bg-dark text-light cardbody"
            style={{
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
              borderColor: "purple",
            }}
          >
            <Card.Body className="text-start">
              <div className="pb-2 pt-2">
                <div class="label one">Creativity</div>
                <div class="progress-bar">
                  <div class={"progress progress-"+ data.skills[0] +" fill-1"}>
                    <div class="glow"></div>
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div class="label one">Social Skills</div>
                <div class="progress-bar">
                  <div class={"progress progress-"+ data.skills[1] +" fill-1"}>
                    <div class="glow"></div>
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div class="label one">Speed</div>
                <div class="progress-bar">
                  <div class={"progress progress-"+ data.skills[2] +" fill-1"}>
                    <div class="glow"></div>
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div class="label one">Humor</div>
                <div class="progress-bar">
                  <div class={"progress progress-"+ data.skills[3] +" fill-1"}>
                    <div class="glow"></div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}
