import React from "react";
import Teamstat from "../../Components/TeamStats/Teamstat";

export default function Team() {
  let teamdata = [
    {
      name: "Jad Barada",
      image: require("../../images/avatars/AvatarMaker.png"),
      title: "Game Designer",
      linkedin: "https://www.linkedin.com/in/jad-barada-1056a1a7/",
      insta: "https://www.instagram.com/jadbarada1/",
      skills: [60, 70, 40, 100],
    },
    {
      name: "Ghazi Al Yaman",
      image: require("../../images/avatars/AvatarMaker_3.png"),
      title: "Game Developer",
      linkedin: "https://www.linkedin.com/in/ghazi-el-yaman-295b0b187/",
      insta: "https://www.instagram.com/ghaziyaman/",
      skills: [60, 40, 90, 70],
    },
    {
      name: "Mohamad Al Yaman",
      image: require("../../images/avatars/AvatarMaker_1.png"),
      title: "2D/3D Artist",
      linkedin: "",
      insta: "https://www.instagram.com/mohamadyaman/",
      skills: [90, 50, 20, 40],
    },
  ];
  return (
    <div className="bg-light">
      <div className="container" style={{ minHeight: "100vh" }}>
        <div class="row py-5"></div>
        <div class="row">
          <h1 style={{ fontSize: 70 }} className="py-5 Bfont text-dark">
            That's us!
          </h1>
        </div>
        <div class="row">
          {teamdata.map((x) => {
            return (
              <div
                class="col-lg-3 col-12 mx-auto"
                style={{ marginBottom: "250px" }}
              >
                <Teamstat data={x} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
