import React from "react";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  MoveOut,
  Sticky,
  StickyIn,
  ZoomIn,
  FadeOut,
  Move,
  MoveIn,
} from "react-scroll-motion";
import "./Story.scss"
import * as Icon from "react-bootstrap-icons";

export default function Story() {
  return (
    <div className="text-white" style={{backgroundColor:"black"}}>
      <div style={{ marginTop: "100vh" }}></div>
      <ScrollContainer>
        <ScrollPage>
          <Animator
            animation={batch(Fade(), Sticky(), MoveOut(0, -200))}
          ></Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator
            animation={batch(Fade(), Sticky(), MoveOut(0, -200))}
          ></Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky())}>
            <span style={{ fontSize: "30px" }}>Let us Share A Story</span>
            <p style={{ fontSize: "30px" }}>
              <Icon.BookFill />
            </p>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator
            style={{ width: "100%" }}
            animation={batch(MoveIn(1000, 0), Sticky(), MoveOut(0, -500))}
          >
            <div class="container-fluid text-center" style={{ width: "100%" }}>
              <div class="row">
                <div class="col-lg-6 col-12 order-lg-0 order-1 designcol"></div>
                <div class="col-lg-6 col-12 order-0 order-lg-1">
                  <p style={{ fontSize: "30px" }}>
                    <Icon.GearFill />
                  </p>
                  <p style={{ fontSize: "30px" }}>Designer</p>
                </div>
              </div>
            </div>
          </Animator>
          <Animator
            style={{ width: "100%" }}
            animation={batch(MoveIn(-1000, 0), Sticky(), MoveOut(0, -500))}
          >
            <div class="container-fluid text-center" style={{ width: "100%" }}>
              <div class="row">
                <div class="col-lg-6 col-12 progcol">
                  <p style={{ fontSize: "30px" }}>
                    <Icon.KeyboardFill />
                  </p>
                  <p style={{ fontSize: "30px" }}>Programmer</p>
                </div>
                <div class="col-6"></div>
              </div>
            </div>
          </Animator>
          <Animator
            style={{ width: "100%" }}
            animation={batch(MoveIn(0, -1000), Sticky(), MoveOut(0, -500))}
          >
            <div class="container-fluid text-center" style={{ width: "100%" }}>
              <div class="row">
                <div class="col artistcol">
                  <p style={{ fontSize: "30px" }}>
                    <Icon.BrushFill />
                  </p>
                  <p style={{ fontSize: "30px" }}>Artist</p>
                </div>
              </div>
            </div>
          </Animator>
          <Animator
            style={{ width: "100%", marginTop: "40vh" }}
            animation={batch(MoveIn(0, -1000), Sticky(), MoveOut(0, -1000))}
          >
            <div class="container-fluid text-center" style={{ width: "100%" }}>
              <div class="row">
                <div class="col">
                  <h1
                    className="btn px-5 py-2 text-light"
                    style={{ fontSize: 30, borderRadius: 20,backgroundColor:"#bd33f4" }}
                  >
                    There once were 3 Game Creators
                  </h1>
                </div>
              </div>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator
            animation={batch(MoveIn(0, 1000), Sticky(), MoveOut(0, -600))}
          >
            <span style={{ fontSize: "30px" }}>
              Who had a vision where they formed a{" "}
              <p>dream team of game developers</p>
            </span>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator
            animation={batch(MoveIn(0, 1000), Sticky(), MoveOut(0, -500))}
          >
            <span style={{ fontSize: "30px" }}>
              By leveraging their{" "}
              <span style={{ color: "#bd33f4" }}>unique skills</span> and{" "}
              <span style={{ color: "#2ae9c9" }}> experiences</span>, they were able
              to create innovative and exciting games that captured the hearts
              of players all around the{" "}
              <span className="bg-light rounded text-dark p-1">World</span>
            </span>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator
            animation={batch(MoveIn(0, 1000), Sticky(),Fade())}
          >
            <span style={{ fontSize: "30px" }}>
              THE END
            </span>
          </Animator>
        </ScrollPage>
      </ScrollContainer>
    </div>
  );
}
